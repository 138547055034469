import { API_URL, METHOD } from '../constants/network';
import { isAreaLeader, serialize } from '../utils';
import { fetchAPI } from '../utils/network';
import { NOTIFICATION_KEY } from '../utils/network';

export const sendNotification = (params) => {
	const { topic, userId, title, body, action, url, areaId, image } = params;
	let receiver;
	if (topic) {
		if (!isAreaLeader && ['coastok', 'noncoastok', 'all'].includes(topic)) {
			receiver = `T|/topics/${topic}`;
		} else {
			switch (topic) {
				case 'areaLeader':
					receiver = 'R|4';
					break;
				case 'asstDirector':
					receiver = 'R|5';
					break;
				case 'facilitator':
					receiver = 'R|3';
					break;
				case 'frontliner':
					receiver = 'R|2';
					break;
				case 'coastok':
					receiver = 'R|1';
					break;
				case 'noncoastok':
					receiver = 'R|0';
					break;
				case 'all':
				default:
					receiver = 'R|0,1,2,3,4'
					break;
			}
		}
	} else if (userId) {
		receiver = `U|${userId}`;
	}
	let requestBody = {
		receiver,
		title,
		body,
		action,
		url,
		areaId,
		image
	};
	return fetchAPI({
		url: API_URL.CUSTOM_NOTIFICATION,
		method: METHOD.POST,
		body: requestBody,
		headers: {
			Authorization: NOTIFICATION_KEY
		}
	});
};

export const getScheduledNotifications = (params) => {
	return fetchAPI({
		url: `${API_URL.SCHEDULE_NOTIFICATION}${serialize(params)}`,
		method: METHOD.GET
	});
};

export const addScheduledNotification = (body) => {
	return fetchAPI({
		url: `${API_URL.SCHEDULE_NOTIFICATION}`,
		method: METHOD.POST,
		body
	});
};

export const updateScheduledNotification = (notificationId, body) => {
	return fetchAPI({
		url: `${API_URL.SCHEDULE_NOTIFICATION}/${notificationId}`,
		method: METHOD.PUT,
		body
	});
};
