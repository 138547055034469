export const VERSION = {
	V1: '/api/v1',
	V2: '/api/v2',
	WEB_V1: '/web/v1',
}

export const METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE',
}

export const API_URL = {
	LOGIN: `${VERSION.V1}/login`,
	VALIDATE: `${VERSION.V1}/verifyToken`,
	QUESTION: `${VERSION.V1}/question`,
	UPLOAD: `${VERSION.V1}/upload`,
	AREA: `${VERSION.V1}/area`,
	SEARCH: `${VERSION.V1}/search`,
	USER: `${VERSION.V1}/user`,
	USER_WEB: `${VERSION.V1}/user/web`,
	SUMMARY: `${VERSION.V1}/summary`,
	STATIC: `${VERSION.V1}/static`,
	APP_TOKEN_LOGIN: `${VERSION.V1}/apptokenlogin`,
	PLEDGE_INIT: `${VERSION.V1}/pledge/init`,
	PLEDGE_DETAIL: `${VERSION.V1}/pledgedetail`,
	PLEDGE_SUMMARY: `${VERSION.V1}/pledging/summary`,
	PLEDGING: `${VERSION.V1}/pledging`,
	RESET_PASSWORD: `${VERSION.V1}/resetpassword`,
	LOGOUT: `${VERSION.V1}/logout`,
	CUSTOM_NOTIFICATION: `${VERSION.V1}/send/gcm`,
	SCHEDULE_NOTIFICATION: `${VERSION.V1}/schedule`,

	// VIPLAVA OPEN APIs
	WEB_USER: `${VERSION.WEB_V1}/user`,
	BOOKING: `${VERSION.WEB_V1}/booking`,
	ORDER: `${VERSION.WEB_V1}/booking/pay`,
	CONFIRM_PAYMENT: `${VERSION.WEB_V1}/booking/payment`,
	SUBSIDY_REQUEST: `${VERSION.WEB_V1}/booking/request`,
	EVENT: `${VERSION.WEB_V1}/event`,
	WEB_INVENTORY: `${VERSION.WEB_V1}/inventory`,

	// EVENT APIs
	EVENT_BOOKING: `${VERSION.V1}/booking`,
	FUND_BALANCE: `${VERSION.V1}/fund/balance`,
	BOOKING_SUBSIDY: `${VERSION.V1}/booking/subsidy`,
	REJECT_BOOKING_SUBSIDY: `${VERSION.V1}/booking/subsidy/reject`,
	FUND: `${VERSION.V1}/fund`,
	CASH_APPROVE: `${VERSION.V1}/booking/cash/approve`,
	INVENTORY: `${VERSION.V1}/inventory`,
	LIBRARY_DATA: `${VERSION.V1}/learning`,

	// Attendance
	// Create Attendance QR
	CREATE_ATTENDANCE_QRS: `${VERSION.V1}/event/attendance`,
	// Get List of Attendance QR
	GET_ATTENDANCE_QRS: `${VERSION.V1}/event/attendance/list`,
	// Get attendacne data of users
	GET_USER_ATTENDANCE: `${VERSION.V1}/event/attendance`,

	// Sadhna API's
	GET_SADHNA: `${VERSION.V1}/sadhna`,

	UPLOAD_S3: `${VERSION.V2}/upload`
}

export const DEFAULT_ERROR_MESSAGE =
	'Something went wrong. Please check your activity and try again.'
