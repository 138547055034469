import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { BarChart } from '@mui/x-charts/BarChart'
import { useTheme } from '@mui/material/styles'
import { format } from 'date-fns'

function getFormattedDate(date) {
	const day = format(date, 'EEE') // Short day name (e.g., "Tue")
	const month = format(date, 'MMM') // Full month name (e.g., "May")
	const dayOfMonth = format(date, 'd') // Numeric day (e.g., "4")

	// Determine the ordinal suffix
	const suffix = getOrdinalSuffix(dayOfMonth)

	return `${day}, ${dayOfMonth}${suffix} ${month}`
}

function getOrdinalSuffix(day) {
	const dayInt = parseInt(day, 10)
	if (dayInt % 10 === 1 && dayInt !== 11) return 'st'
	if (dayInt % 10 === 2 && dayInt !== 12) return 'nd'
	if (dayInt % 10 === 3 && dayInt !== 13) return 'rd'
	return 'th'
}

function getLastDayPercentageChange(weeklySadhnaCounts) {
	const perChange = (
		(weeklySadhnaCounts[weeklySadhnaCounts.length - 2]?.uniqueUserCount -
			weeklySadhnaCounts[weeklySadhnaCounts.length - 3]?.uniqueUserCount) /
		weeklySadhnaCounts[weeklySadhnaCounts.length - 3]?.uniqueUserCount
	).toFixed(2)
	return perChange > 0 ? `+${perChange}` : `-${perChange}`
}

export default function WeekelySadhnaBarChart({ weeklySadhnaCounts }) {
	const theme = useTheme()
	const colorPalette = [
		(theme.vars || theme).palette.primary.dark,
		(theme.vars || theme).palette.primary.main,
		(theme.vars || theme).palette.primary.light,
	]

	const xAxisData = weeklySadhnaCounts.map(
		(el) => `${getFormattedDate(el.date)}`
	)

	if (weeklySadhnaCounts.length === 0) {
		return null
	}

	return (
		<Card variant="outlined" sx={{ width: '100%' }}>
			<CardContent>
				<Typography component="h2" variant="subtitle2" gutterBottom>
					Weekly Sadhna Updates
				</Typography>
				<Stack sx={{ justifyContent: 'space-between' }}>
					<Stack
						direction="row"
						sx={{
							alignContent: { xs: 'center', sm: 'flex-start' },
							alignItems: 'center',
							gap: 1,
						}}
					>
						<Typography variant="h4" component="p">
							{
								weeklySadhnaCounts[
									weeklySadhnaCounts.length - 2
								].uniqueUserCount
							}
						</Typography>
						<Chip
							size="small"
							color={
								getLastDayPercentageChange(weeklySadhnaCounts) >
									0
									? 'success'
									: 'error'
							}
							label={`${getLastDayPercentageChange(
								weeklySadhnaCounts
							)}%`}
						/>
					</Stack>
					<Typography
						variant="caption"
						sx={{ color: 'text.secondary' }}
					>
						Sadhna updates for yesterday
					</Typography>
				</Stack>
				<BarChart
					borderRadius={8}
					colors={colorPalette}
					xAxis={[
						{
							scaleType: 'band',
							categoryGapRatio: 0.5,
							data: xAxisData,
						},
					]}
					series={[
						{
							id: 'page-views',
							label: 'Unique Users',
							data: weeklySadhnaCounts.map(
								(el) => el.uniqueUserCount
							),
						},
					]}
					height={250}
					margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
					grid={{ horizontal: true }}
					slotProps={{
						legend: {
							hidden: true,
						},
					}}
				/>
			</CardContent>
		</Card>
	)
}
