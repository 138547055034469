import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { theme } from './constants/theme'
import './index.css'
import { CssBaseline } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { GOOGLE_CLIENT_ID } from './constants/config'
import { GoogleOAuthProvider } from '@react-oauth/google'
import {
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error) => {
			console.error('React Query Error:', error)
		},
	}),
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 60, // Cache data is fresh for 5 minutes
			cacheTime: 1000 * 60 * 60 * 24, // Keep stale data in cache for 1 hour
		},
	},
})

const container = document.getElementById('root')
const root = createRoot(container)

// In-memory persister
const memoryPersister = createSyncStoragePersister({
	storage: window.localStorage,
})

persistQueryClient({
	queryClient,
	persister: memoryPersister,
	maxAge: 1000 * 60 * 60 * 24, // Cache is valid for 1 hour
})

root.render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
				<StyledEngineProvider injectFirst>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<App />
							<ReactQueryDevtools initialIsOpen={false} />
						</ThemeProvider>
					</LocalizationProvider>
				</StyledEngineProvider>
			</GoogleOAuthProvider>
		</QueryClientProvider>
	</Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
