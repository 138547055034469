import React, { useCallback, useEffect, useState } from 'react'
import { getUserAttendanceList } from '../../services/event'
import './style.scss'
import { Button, Card, Typography } from '@mui/material'
import { exportFromJSON, isMobileScreen } from '../../utils'
import { format } from 'date-fns'
import { CustomDataGrid } from '../../components/CustomTable/CustomDataGrid'
import { compileReport, generateReportAreaWise } from './report-generator'
import { useQuery } from '@tanstack/react-query'

const fetchAttendanceData = async (eventId) => {
	const response = await getUserAttendanceList({
		page: 1,
		per_page: 500,
		eventId: eventId,
	})
	return response
}

const AreaWiseAttendanceScreen = ({ eventDetail, attendanceQrData }) => {
	const [filters, setFilters] = useState({
		connectedAreaId: '',
		facilitator: null,
		eventId: '',
		pageSize: 50,
		page: 0,
	})
	const [filterOptions] = useState({
		facilitatorOptions: [],
		sessionOptions: [],
	})
	const { isLoading, data: queryData } = useQuery({
		queryKey: ['attendanceData', eventDetail.id], // Query key
		queryFn: () => fetchAttendanceData(eventDetail.id), // Query function
		refetchInterval: 5000,
	})

	const generatedReport = queryData
		? compileReport({
				result: [queryData],
				attendanceQrData,
		  })
		: {}
	const finalData = generateReportAreaWise(generatedReport?.attendanceMatrix)

	const onPageSizeChange = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page,
			pageSize,
		}))
	}

	const rowWidthConfig = {
		ConnectedArea: { default: { flex: 0.1 }, mobile: { width: 150 } },
		TotalPresent: { default: { flex: 0.1 }, mobile: { width: 150 } },
		TotalLate: { default: { flex: 0.1 }, mobile: { width: 150 } },
		Total: { default: { flex: 0.1 }, mobile: { width: 150 } },
	}

	const headCells = [
		{
			field: 'ConnectedArea',
			numeric: false,
			headerName: 'Area',
			sortable: false,
		},
		{
			field: 'TotalPresent',
			numeric: false,
			headerName: 'Present',
			sortable: false,
		},
		{
			field: 'TotalLate',
			numeric: false,
			headerName: 'Late',
			sortable: false,
		},
		{
			field: 'Total',
			headerName: 'Total',
			renderCell: (params) => {
				return (
					<div>
						{params?.row?.TotalPresent + params?.row?.TotalLate}
					</div>
				)
			},
		},
	]

	const download = () => {
		const todayDate = format(new Date(), 'dd-MM-yyyy')
		const eventName = filterOptions.sessionOptions?.find(
			(el) => el._id === filters.eventId
		)?.title
		const fileName =
			`${eventName || 'All Events'} ` + 'Attendance ' + todayDate

		exportFromJSON({
			data: finalData?.map((s) => ({
				ConnectedArea: s.ConnectedArea,
				TotalPresent: s.TotalPresent,
				TotalLate: s.TotalLate,
			})),
			fileName,
		})
	}

	return (
		<Card className="p-5">
			<Typography variant="h4">Area Wise Attendance List</Typography>
			<Button
				onClick={() => download()}
				variant="contained"
				className="mb-2 mt-2"
			>
				Download Report
			</Button>
			<div className="screen report-screen mt-3">
				<CustomDataGrid
					rows={
						finalData.map((s) => ({
							id: s.ConnectedArea,
							ConnectedArea: s.ConnectedArea,
							TotalPresent: s.TotalPresent,
							TotalLate: s.TotalLate,
						})) || []
					}
					columns={headCells.map((col) => ({
						...col,
						sortable: true,
						disableClickEventBubbling: true,
						...(isMobileScreen()
							? rowWidthConfig[col.field].mobile ||
							  rowWidthConfig[col.field].default
							: rowWidthConfig[col.field].default),
					}))}
					pageSize={filters.per_page}
					rowCount={finalData.length}
					loading={isLoading}
					paginationModel={filters}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: filters.pageSize,
							},
						},
					}}
					onPaginationModelChange={(page) =>
						onPageSizeChange({
							pageSize: page.pageSize,
							page:
								page.pageSize != filters.pageSize
									? 0
									: page.page,
						})
					}
				/>
			</div>
		</Card>
	)
}

export default AreaWiseAttendanceScreen
