import {
	Box,
	Button,
	Card,
	CircularProgress,
	Dialog,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import {
	createOrUpdateAttendanceQRRequestAction,
	createOrUpdateInventoryRequestAction,
	getAttendanceQRsRequestAction,
} from '../../actions/event'
import { isMobileScreen } from '../../utils'
import {
	CancelRounded,
	Done,
	DownloadForOffline,
	VisibilityRounded,
	QrCode,
} from '@mui/icons-material'
import { closeDialog } from '../../actions'
import QRIcon from './assets/qr_code_2.svg'
import { getFullFormattedDateTimeString } from '../../components/Calender/helpers'
import { CustomDataGrid } from '../../components/CustomTable/CustomDataGrid'
import { GridLoadIcon } from '@mui/x-data-grid'
import AreaWiseAttendanceScreen from './AreaWiseAttendanceScreen'
import { generateAndDownloadReport } from './report-generator'
const PREFIX = 'ManageQRScreen'

const classes = {
	body: `${PREFIX}-body`,
	modalRoot: `${PREFIX}-modalRoot`,
	modal: `${PREFIX}-modal`,
	qrModalContainer: `${PREFIX}-qrModalContainer`,
	qrtitle: `${PREFIX}-qrTitle`,
}

const styles = {
	ModalBox: {
		width: 800,
		backgroundColor: 'white',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}

const StyledCard = styled(Card)({
	[`& .${classes.body}`]: {
		flexDirection: 'column',
	},
	[`& .${classes.modalRoot}`]: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	[`& .${classes.modal}`]: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.qrtitle}`]: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	[`& .${classes.qrModalContainer}`]: {
		width: '80vh',
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})

const SESSION_CATEGORY = ['Camp', 'Facilitator', 'Area Leader']

const ManageQRScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		categories: [],
		pageSize: 100,
		page: 0,
	})
	const [All_CATEGORY, setAllCategory] = useState([])
	const [modalData, setModalData] = useState(null)
	const [qrUrlModal, showQRModal] = useState(null)
	const [liveActivityData, showLiveActivity] = useState(null)
	const [isAttendanceReportLoader, setAttendanceReportLoader] =
		useState(false)
	const eventReducer = useSelector((state) => state.eventReducer)
	const loading = eventReducer.loading
	const attendanceQrData = eventReducer?.attendanceQrData

	const [selectedRows, setSelectedRows] = useState([])

	const dispatch = useDispatch()

	const loadInitialData = useCallback(() => {
		dispatch(
			getAttendanceQRsRequestAction({
				page: filters.page + 1,
				per_page: filters.pageSize,
				categories: filters.categories?.join(','),
				id: '',
				eventId,
				status: 1,
			})
		)
	}, [dispatch, filters, eventId, filters.categories])

	useEffect(() => {
		if (filters.categories == '') {
			setAllCategory([
				...new Set([
					...SESSION_CATEGORY,
					...(attendanceQrData?.data
						?.map((ev) => ev.category)
						?.filter(Boolean) || []),
				]),
			])
		}
	}, [attendanceQrData])

	useEffect(() => {
		loadInitialData()
	}, [filters.page, filters.pageSize, filters.categories])

	const headCells = [
		{
			field: 'title',
			numeric: false,
			headerName: 'Title',
			sortable: false,
		},
		{
			field: 'speaker',
			numeric: false,
			headerName: 'Speaker',
			sortable: false,
		},
		{
			field: 'category',
			numeric: false,
			headerName: 'Category',
			sortable: false,
		},
		{
			field: 'attendanceTime',
			numeric: false,
			headerName: 'Attendance Time (min)',
			sortable: false,
		},
		{
			field: 'classDuration',
			numeric: false,
			headerName: 'Class Duration (min)',
			sortable: false,
		},
		{
			field: 'status',
			numeric: false,
			headerName: 'Status',
			sortable: false,
		},
		{
			field: 'createdAt',
			numeric: false,
			headerName: 'Created At',
			sortable: false,
		},
		{
			field: 'id',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<div className="container mx-auto">
					<IconButton
						onClick={() => {
							showQRModal(params?.row)
						}}
						aria-label="Edit"
						color="primary"
						title="Edit"
						size="small"
					>
						<img src={QRIcon} alt="QR Icon" />
					</IconButton>
					<IconButton
						onClick={() => {
							showLiveActivity(params?.row)
						}}
						aria-label="Edit"
						color="primary"
						title="Edit"
						size="small"
						name="eye"
						className="ml-2"
					>
						<VisibilityRounded />
					</IconButton>
				</div>
			),
		},
	]

	const rowWidthConfig = headCells.reduce((acc, value) => {
		acc[value.field] = { default: { flex: 0.15 }, mobile: { width: 150 } }
		return acc
	}, {})

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page,
			pageSize,
		}))
	}

	const onChange = (key, value) => {
		setModalData((prevValue) => ({
			...prevValue,
			[key]: value,
		}))
	}

	const validateData = (data) => {
		return data?.title
	}

	const submitRequest = (deleteId = false) => {
		if (!validateData(modalData) && !deleteId) return
		if (!deleteId) {
			const { id } = modalData
			modalData.eventId = eventId
			dispatch(
				createOrUpdateAttendanceQRRequestAction({
					id,
					data: modalData,
				})
			).then((resp) => {
				setModalData(null)
				loadInitialData()
			})
		} else {
			dispatch(closeDialog())
			console.log(deleteId)
			dispatch(
				createOrUpdateInventoryRequestAction({
					id: deleteId,
					attendanceQrData: { status: 0 },
				})
			).then((resp) => {
				setModalData(null)
				loadInitialData()
			})
		}
	}

	const downloadImage = async (imageUrl, title) => {
		try {
			// Fetch the image data
			const response = await fetch(imageUrl)
			const blob = await response.blob()

			// Create a URL for the Blob
			const url = window.URL.createObjectURL(blob)

			// Create a link element
			const a = document.createElement('a')
			a.href = url

			// Set the download attribute with a file name
			a.download = `${title}.png`

			// Append the link to the body and simulate a click
			document.body.appendChild(a)
			a.click()

			// Clean up and remove the link
			a.remove()
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Failed to download image:', error)
		}
	}

	const generateAttendanceReport = () => {
		generateAndDownloadReport({
			selectedRows,
			attendanceQrData,
			toggleLoader: setAttendanceReportLoader,
		})
	}

	const handleSelectionChange = (selectionModel) => {
		setSelectedRows(selectionModel) // Update selected row IDs
	}

	const onCategoryChange = (value) => {
		setFilters((f) => ({
			...f,
			categories: value,
		}))
	}

	return (
		<StyledCard className="p-3">
			<div className="screen overview-screen">
				<div className="filter-header mb-2">
					<Typography variant="h4">Attendance QR Code</Typography>
				</div>
				<div className="filter-header">
					<div style={{ width: '20%' }}>
						<FormControl
							variant="outlined"
							size="small"
							className="d-flex width-50"
							style={{ width: '100%' }}
						>
							<InputLabel>Classs Category</InputLabel>
							<Select
								multiple
								value={filters.categories}
								onChange={(e) =>
									onCategoryChange(e.target.value)
								}
								label="Class Category"
								name="classCategory"
							>
								{All_CATEGORY.map((value) => (
									<MenuItem value={value}>{value}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>

					<div className="mb-5">
						<Button
							variant="contained"
							color="primary"
							style={{ marginRight: '10px' }}
							disabled={isAttendanceReportLoader}
							onClick={generateAttendanceReport}
							startIcon={
								isAttendanceReportLoader ? (
									<CircularProgress size={20} />
								) : (
									<DownloadForOffline />
								)
							}
						>
							Generate Attendance Report
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setModalData({})}
							startIcon={<QrCode />}
						>
							Create QR
						</Button>
					</div>
				</div>
				{!!modalData && (
					<Dialog
						open={!!modalData}
						onClose={() => setModalData(null)}
						maxWidth="md"
						onBackdropClick={() => setModalData(null)}
					>
						<Box sx={styles.ModalBox}>
							<form noValidate autoComplete="off">
								<Typography variant="h3">
									{modalData?._id
										? 'Update Attendance QR'
										: 'Create Attendance QR'}
								</Typography>
								<TextField
									className="m-top-16"
									label="Title"
									variant="outlined"
									defaultValue={modalData.title}
									value={modalData.title}
									name="title"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<TextField
									className="m-top-16"
									label="Speaker Name"
									variant="outlined"
									defaultValue={modalData.speaker}
									value={modalData.speaker}
									name="speaker"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<TextField
									className="m-top-16"
									label="Meeting Link"
									variant="outlined"
									defaultValue={modalData.link}
									value={modalData.link}
									name="link"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<TextField
									className="m-top-16"
									label="Attendance Time (Min)"
									variant="outlined"
									defaultValue={modalData.att_time_min}
									value={modalData.att_time_min}
									name="att_time_min"
									type="number"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<TextField
									className="m-top-16"
									label="Class Duration (Min)"
									variant="outlined"
									defaultValue={modalData.class_duration}
									value={modalData.class_duration}
									name="class_duration"
									type="number"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<FormControl fullWidth className="m-top-16">
									<InputLabel>Category</InputLabel>
									<Select
										value={modalData.category}
										label="Category"
										name="category"
										onChange={(e) =>
											onChange(
												e.target.name,
												e.target.value
											)
										}
									>
										{[
											...new Set([
												...SESSION_CATEGORY,
												...attendanceQrData?.data
													?.map((ev) => ev.category)
													.filter(Boolean),
											]),
										].map((value) => (
											<MenuItem value={value}>
												{value}
											</MenuItem>
										))}
										<MenuItem value={''}>Other</MenuItem>
									</Select>

									{!SESSION_CATEGORY.includes(
										modalData.category
									) && (
										<>
											<TextField
												className="m-top-16"
												label="Please enter a category to create new"
												variant="outlined"
												defaultValue={
													modalData.category
												}
												value={modalData.category}
												name="category"
												onChange={(e) =>
													onChange(
														e.target.name,
														e.target.value
													)
												}
												fullWidth
											/>
										</>
									)}
								</FormControl>
							</form>

							<div className="m-top-16">
								<Button
									variant="contained"
									color="secondary"
									startIcon={<CancelRounded />}
									onClick={() => setModalData(null)}
									aria-label="Reject"
								>
									Close
								</Button>
								&nbsp; &nbsp; &nbsp;
								{loading ? (
									<GridLoadIcon />
								) : (
									<Button
										variant="contained"
										color="primary"
										startIcon={<Done />}
										onClick={() => submitRequest()}
										aria-label="Reject"
									>
										{modalData?.id ? 'Update' : 'Create'}
									</Button>
								)}
							</div>
						</Box>
					</Dialog>
				)}
				{!!qrUrlModal && (
					<div className={classes.modalRoot}>
						<Dialog
							open={!!qrUrlModal}
							onClose={() => showQRModal(null)}
							className={classes.modal}
							onBackdropClick={() => showQRModal(null)}
							sx={{
								textAlign: 'center',
							}}
						>
							<div className={classes.qrModalContainer}>
								<img
									src={qrUrlModal.url}
									alt="QR"
									style={{
										height: '70vh',
										width: '70vh',
									}}
								/>
								<Button
									onClick={() =>
										downloadImage(
											qrUrlModal.url,
											qrUrlModal.title
										)
									}
									variant="contained"
									className="mb-2 mt-2"
								>
									Download QR
								</Button>
							</div>
						</Dialog>
					</div>
				)}
				{!!liveActivityData && (
					<div className={classes.modalRoot}>
						<Dialog
							open={!!liveActivityData}
							onClose={() => showLiveActivity(null)}
							className={classes.modal}
							onBackdropClick={() => showLiveActivity(null)}
							sx={{
								textAlign: 'center',
							}}
							maxWidth={'lg'}
						>
							<div
								style={{
									height: '100vh',
									width: '100vh',
								}}
							>
								<AreaWiseAttendanceScreen
									eventDetail={liveActivityData}
									attendanceQrData={attendanceQrData}
								/>
							</div>
						</Dialog>
					</div>
				)}
				<div className="m-top-16">
					<CustomDataGrid
						rows={
							attendanceQrData?.data?.map((inv) => ({
								id: inv._id,
								title: inv.title,
								speaker: inv.speaker,
								category: inv.category,
								attendanceTime: inv.att_time_min
									? `${inv.att_time_min} min`
									: '',
								classDuration: inv.class_duration
									? `${inv.class_duration} min`
									: '',
								status:
									inv.status === 1 ? 'Active' : 'Inactive',
								updatedAt: inv.updatedAt,
								url: inv.url,
								createdBy: inv.createdBy,
								createdAt: getFullFormattedDateTimeString(
									inv.createdAt
								),
								addedBy: inv.addedBy,
							})) || []
						}
						columns={headCells?.map((col) => ({
							...col,
							sortable: true,
							disableClickEventBubbling: true,
							// // resizable: true,
							...(isMobileScreen()
								? rowWidthConfig[col.field].mobile ||
								  rowWidthConfig[col.field].default
								: rowWidthConfig[col.field].default),
						}))}
						pageSize={filters.per_page}
						rowCount={attendanceQrData?.data?.length}
						loading={loading}
						paginationModel={filters}
						checkboxSelection
						onRowSelectionModelChange={(newSelectionModel) => {
							handleSelectionChange(newSelectionModel)
						}}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: filters.pageSize,
								},
							},
						}}
						selectionModel={selectedRows}
						onPaginationModelChange={(page) =>
							handleUserData({
								pageSize: page.pageSize,
								page:
									page.pageSize != filters.pageSize
										? 0
										: page.page,
							})
						}
					/>
				</div>
			</div>
		</StyledCard>
	)
}

export default ManageQRScreen
