import React from 'react'
import { styled } from '@mui/material/styles'
import {
	Button,
	IconButton,
	InputAdornment,
	Modal,
	Paper,
	TextField,
	Typography,
} from '@mui/material'
import { DataGrid, GridSearchIcon } from '@mui/x-data-grid'
import { AddCircle, Delete, Edit, Visibility } from '@mui/icons-material'
import { getStaticContent, updateStaticContent } from '../../services/static'
import UpdateContent from './UpdateContent'
import { connect } from 'react-redux'
import { FEST_TYPE, FILE_TYPE, STATUS } from '../../constants'
import { format } from 'date-fns'
import { isAreaLeader, isGenSecretary, isMobileScreen } from '../../utils'
import { closeDialog, showDialog } from '../../actions'
import { withRouter } from '../../hooks/withRouter'
import { CustomDataGrid } from '../../components/CustomTable/CustomDataGrid'

const PREFIX = 'index'

const classes = {
	tableHeader: `${PREFIX}-tableHeader`,
}

const Root = styled('div')({
	[`& .${classes.tableHeader}`]: {
		padding: '20px',
		marginBottom: '20px',
	},
})

class ManageAppContent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			loading: false,
			filters: {
				page: 0,
				pageSize: 10,
			},
		}
		this.isMobile = isMobileScreen()
	}

	componentDidMount() {
		this.getStaticData()
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.router?.params?.type !== this.props.router?.params?.type
		) {
			this.getStaticData()
		}
	}

	getStaticData = () => {
		const { type } = this.props.router.params
		this.setState({ loading: true })
		getStaticContent({
			fileType: type === 'images' ? 3 : 2,
			per_page: 100,
		})
			.then((res) => {
				this.setState({
					data: res.data.data,
					loading: false,
				})
			})
			.catch(() => {
				this.setState({ loading: false })
			})
	}

	viewContent = (row) => {
		this.setState({
			modalData: row,
			modalType: 'VIEW',
		})
	}

	editContent = (row) => {
		this.setState({
			modalData: row,
			modalType: 'EDIT',
		})
	}

	deleteContent = (content) => {
		if (content._id) {
			this.props.showDialog({
				title: 'Delete this Content?',
				description:
					"Are you sure to delete this Content? This action won't be reverted",
				actions: [
					{
						action: this.props.closeDialog,
						text: 'Cancel',
						color: 'secondary',
					},
					{
						action: () => {
							updateStaticContent(content._id, {
								status: 0,
							}).then(() => {
								this.props.closeDialog()
								this.setState({
									data: this.state.data.filter(
										(d) => d._id !== content._id
									),
								})
							})
						},
						text: 'Yes, Delete It',
					},
				],
			})
		}
	}

	onModalClose = () => {
		this.setState({
			modalData: null,
		})
	}

	handleSearch = (searchString) => {
		this.setState({
			searchValue: searchString,
		})
	}

	searchRows = (row, searchValue) => {
		return (
			row.name &&
			row.name.toUpperCase().includes(searchValue.toUpperCase())
		)
	}

	populateData = (files, isEditAccess) => {
		const rowWidthConfig = {
			name: { default: { flex: 0.25 }, mobile: { width: 200 } },
			festType: { default: { flex: 0.15 }, mobile: { width: 120 } },
			createdAt: { default: { width: 130 } },
			status: { default: { flex: 0.15 }, mobile: { width: 120 } },
			file: {
				default: {
					width: 160,
				},
			},
		}
		const headCells = [
			{
				field: 'name',
				numeric: false,
				headerName: 'Name',
				sortable: false,
			},
			{
				field: 'festType',
				numeric: false,
				headerName: 'Fest Type',
				sortable: false,
			},
			{
				field: 'createdAt',
				numeric: false,
				headerName: 'Created At',
				sortable: false,
			},
			{
				field: 'status',
				numeric: false,
				headerName: 'Status',
				sortable: true,
			},
			{
				field: 'file',
				headerName: 'Actions',
				sortable: false,
				filterable: false,
				renderCell: (params) => (
					<strong>
						<IconButton
							onClick={() => this.viewContent(params.value)}
							aria-label="Edit"
							color="primary"
							title="Edit"
							size="large"
						>
							<Visibility />
						</IconButton>
						<IconButton
							onClick={() => this.editContent(params.value)}
							aria-label="Edit"
							color="primary"
							title="Edit"
							size="large"
						>
							<Edit />
						</IconButton>
						<IconButton
							onClick={() => this.deleteContent(params.value)}
							aria-label="Delete"
							color="primary"
							title="Delete"
							size="large"
						>
							<Delete />
						</IconButton>
					</strong>
				),
			},
		]
		const data = [
			{
				btnLabel: 'View',
				btnClick: this.viewContent,
			},
		]
		if (isEditAccess) {
			data.push({
				btnLabel: 'Edit',
				btnClick: this.editContent,
			})
		}
		const {
			total_results,
			router: {
				params: { type },
			},
		} = this.props
		const { modalData, modalType, filters } = this.state
		return (
			<Root className="file-container">
				{modalData && (
					<Modal open={!!modalData} onClose={this.onModalClose}>
						{modalType === 'VIEW' ? (
							<div className="c-modal file-info-modal">
								{/* {JSON.stringify(modalData)} */}
								<Typography variant="h5">
									{modalData.name}
								</Typography>
								<Typography variant="h6">
									Category: {FEST_TYPE[modalData.festType]}
								</Typography>
								{modalData.fileType === 2 && (
									<iframe
										width="420"
										height="315"
										src={`https://www.youtube.com/embed/${modalData.url}`}
										title={modalData.name}
									></iframe>
								)}
								{modalData.fileType === 3 && (
									<img
										width="420"
										src={modalData.url}
										title={modalData.name}
										alt={modalData.name}
									/>
								)}
							</div>
						) : (
							<div className="c-modal">
								<UpdateContent
									fileId={modalData._id}
									name={modalData.name}
									url={
										modalData.fileType === 2
											? modalData.url &&
											(modalData.url.includes(
												'https://www.youtube.com/watch?v='
											)
												? modalData.url
												: `https://www.youtube.com/watch?v=${modalData.url}`)
											: modalData.url
									}
									fileType={modalData.fileType}
									festType={modalData.festType}
									closeHandler={this.onModalClose}
								/>
							</div>
						)}
					</Modal>
				)}
				<Paper className="p-3">
					<div className="table-header-container">
						<TextField
							id="input-with-icon-textfield"
							label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<GridSearchIcon />
									</InputAdornment>
								),
							}}
							onChange={(e) => this.handleSearch(e.target.value)}
							autoComplete="off"
							value={this.state.searchValue}
						/>
						<div>
							<Button
								startIcon={<AddCircle />}
								color="primary"
								variant="contained"
								onClick={() =>
									this.editContent({
										fileType: type === 'images' ? 3 : 2,
									})
								}
							>
								Add Content
							</Button>
							{/* &nbsp; &nbsp; &nbsp;
							<Button
								startIcon={<CloudDownload />}
								color="primary"
								variant="contained"
								onClick={this.download}
							>
								Download
							</Button> */}
						</div>
					</div>
				</Paper>
				<div style={{ width: '100%', background: 'white', padding: '10px' }}>
					<CustomDataGrid
						rows={files
							.filter((file) => {
								return this.state.searchValue
									? this.searchRows(
										file,
										this.state.searchValue
									)
									: true
							})
							.map((file) => ({
								id: file._id,
								createdAt: format(
									new Date(file.createdAt),
									'dd MMM yyyy'
								),
								status: STATUS[file.status],
								fileType: FILE_TYPE[file.fileType],
								festType: FEST_TYPE[file?.festType],
								name: file?.name,
								file,
							}))}
						columns={headCells.map((col) => ({
							...col,
							sortable: true,
							disableClickEventBubbling: true,
							...(this.isMobile
								? rowWidthConfig[col.field].mobile ||
								rowWidthConfig[col.field].default
								: rowWidthConfig[col.field].default),
						}))}
						loading={this.state.loading}
						paginationModel={filters}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: filters.pageSize,
								},
							},
						}}
						rowCount={files.length}
						paginationMode="client"
						onPaginationModelChange={(page) =>
							this.setState((prevState) => ({
								...prevState,
								filters: {
									pageSize: page.pageSize,
									page:
										page.pageSize !=
											prevState?.filters?.pageSize
											? 0
											: page.page,
								},
							}))
						}
					/>
				</div>
			</Root>
		)
	}

	render() {
		const { userProfile } = this.props
		return (
			<div className="user-container">
				<div>
					{isAreaLeader(userProfile) || isGenSecretary(userProfile)
						? this.populateData(this.state.data, true)
						: this.populateData(this.state.data, false)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	userProfile: state.commonContent.file,
})

const mapDispatchToProps = {
	closeDialog,
	showDialog,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ManageAppContent))
