import moment from 'moment'
import { getUserAttendanceList } from '../../services/event'
const ExcelJS = require('exceljs')
var FileSaver = require('file-saver')

export const generateReportAreaWise = (attendanceMatrix) => {
	if (!attendanceMatrix) return []
	const result = attendanceMatrix.reduce((acc, curr) => {
		let area = curr.ConnectedArea
		if (!area) {
			area = 'Unknown'
		}
		if (!acc[area]) {
			acc[area] = { TotalPresent: 0, TotalLate: 0 }
		}
		acc[area].TotalPresent += curr['Total Present']
		acc[area].TotalLate += curr['Total Late']
		return acc
	}, {})

	// Convert result to an array of objects for better readability
	const groupedResult = Object.entries(result).map(([area, counts]) => ({
		ConnectedArea: area,
		TotalPresent: counts.TotalPresent,
		TotalLate: counts.TotalLate,
	}))

	return groupedResult
}

export const compileReport = ({ result, attendanceQrData }) => {
	const attendanceRecords = result.map((res) => res?.data?.data)

	// Step 1: Flatten nested arrays
	const flattenedRecords = attendanceRecords.flat()

	// Step 2: Group attendance by event
	const recordsByEvent = attendanceRecords.reduce(
		(acc, eventRecords, index) => {
			const eventName = eventRecords[0]?.eventId?.title
			if (eventName) {
				acc[eventName] = eventRecords
			}
			return acc
		},
		{}
	)

	// Step 3: Calculate the most common 15-minute range per event
	const eventTimeRanges = {}

	Object.keys(recordsByEvent).forEach((eventName) => {
		const eventData = attendanceQrData?.data?.find(
			(record) => record?.title === eventName
		)
		const timestamps = recordsByEvent[eventName].map((record) =>
			moment(record.createdAt).valueOf()
		)

		const minStartTime = Math.min(...timestamps)

		eventTimeRanges[eventName] = {
			startTime: parseInt(minStartTime, 10),
			endTime: moment(parseInt(minStartTime, 10))
				.add(eventData?.att_time_min ?? 15, 'minutes')
				.valueOf(),
		}
	})

	// Step 4: Extract unique participants and events
	const participants = [
		...new Set(flattenedRecords.map((record) => record.userId._id)),
	]
	const events = Object.entries(eventTimeRanges)
		.sort((a, b) => a[1].startTime - b[1].startTime)
		.map((el) => el[0])

	// Map userId to name for later use
	const userIdToName = {}
	const userIdToArea = {}
	flattenedRecords.forEach((record) => {
		if (!userIdToName[record.userId._id]) {
			userIdToName[record.userId._id] = record.userId?.name
			userIdToArea[record.userId._id] = record.connectedAreaId?.name
		}
	})

	// Step 5: Build attendance matrix with totals
	let attendanceMatrix = participants.map((participantId, index) => {
		let totalPresent = 0
		let totalAbsent = 0
		let totalLate = 0

		const row = {
			Name: userIdToName[participantId],
			ConnectedArea: userIdToArea[participantId],
		}

		events.forEach((event) => {
			const eventRecords = recordsByEvent[event]
			const record = eventRecords.find(
				(r) => r.userId._id === participantId
			)
			const eventData = attendanceQrData?.data?.find(
				(record) => record?.title === event
			)
			if (record) {
				const attendanceTime = moment(record.createdAt).valueOf()
				const { startTime } = eventTimeRanges[event]

				// Check if attendance is within the most common 15-min range
				if (
					attendanceTime >= startTime &&
					attendanceTime <
						moment(startTime).add(
							eventData?.att_time_min ?? 15,
							'minutes'
						)
				) {
					row[event] = 'P'
					totalPresent++
				} else if (
					attendanceTime >
						moment(startTime).add(
							eventData?.att_time_min ?? 15,
							'minutes'
						) &&
					attendanceTime <
						moment(startTime).add(
							eventData?.att_time_min +
								eventData?.class_duration ?? 120,
							'minutes'
						)
				) {
					row[event] = `L (${moment(record.createdAt).format(
						'hh:mm A'
					)})`
					totalLate++
				} else {
					row[event] = `A (${moment(record.createdAt).format(
						'hh:mm A'
					)})`
					totalAbsent++
				}
			}
		})

		// Add total present, late, and absent columns
		row['Total Present'] = totalPresent
		row['Total Late'] = totalLate
		row['Total Absent'] = totalAbsent

		return row
	})

	// Step 6: Sort the matrix alphabetically by the Name property
	attendanceMatrix = attendanceMatrix.sort(
		(a, b) => a?.ConnectedArea?.localeCompare(b.ConnectedArea) || -1
	)

	return {
		attendanceMatrix,
		attendanceRecords,
		attendanceQrData,
		events,
		eventTimeRanges,
		recordsByEvent,
	}
}

export const compileSummaryAndDownload = ({
	attendanceMatrix,
	attendanceRecords,
	attendanceQrData,
	events,
	eventTimeRanges,
	recordsByEvent,
}) => {
	let totalPercentage = 0
	// Rearrange columns to have "Total Present", "Total Late", and "Total Absent" first
	const formattedAttendanceMatrix = attendanceMatrix.map((record, index) => {
		const { Name, ConnectedArea, ...rest } = record
		const {
			'Total Present': totalPresent,
			'Total Late': totalLate,
			'Total Absent': totalAbsent,
			...events
		} = rest
		const finalEvents = {}
		Object.entries(events).forEach(([eventName, eventValue]) => {
			const modifiedEventName = eventName
			finalEvents[modifiedEventName] = eventValue
		})
		const percentage =
			((totalPresent + totalLate) * 100) / attendanceRecords.length
		totalPercentage += percentage
		return {
			'S.No': index + 1,
			Name,
			'Connected Area': ConnectedArea,
			'Total Present': totalPresent,
			'Total Late': totalLate,
			'Total Absent': totalAbsent,
			Percentage: `${Math.floor(percentage)}%`,
			...finalEvents,
		}
	})
	const AverageAttendance = Math.floor(
		totalPercentage / attendanceMatrix.length
	)

	const speakerRow = {
		'S.No': '',
		Name: 'Attendance Summary',
		'Connected Area': '',
		'Total Present': '',
		'Total Late': '',
		'Total Absent': '',
		Percentage: 'Speaker Details :',
	}
	events.forEach((event) => {
		const eventData = attendanceQrData?.data?.find(
			(record) => record?.title === event
		)
		const speakerName = eventData?.speaker || 'N/A'
		speakerRow[event] = speakerName ? `By ${speakerName}` : ''
	})

	// Add the second row: Start and End times
	const dateRow = {
		'S.No': '',
		Name: 'Total Classes',
		'Connected Area': '',
		'Total Present': events.length,
		'Total Late': '',
		'Total Absent': '',
		Percentage: 'Attendance Date :',
	}
	events.forEach((event) => {
		const { startTime } = eventTimeRanges[event]
		// const eventData = attendanceQrData?.data?.find(
		// 	(record) => record?.title === event
		// )
		// const endTime = moment(startTime)
		// 	.add(eventData?.class_duration ?? 120, 'minutes')
		// 	.valueOf()
		dateRow[event] = `${moment(startTime).format('Do MMMM YYYY')}`
	})

	// Add the second row: Start and End times
	const timingRow = {
		'S.No': '',
		Name: 'Average Attendance',
		'Connected Area': '',
		'Total Present': `${AverageAttendance} %`,
		'Total Late': '',
		'Total Absent': '',
		Percentage: 'Attendance Timings: ',
	}
	events.forEach((event) => {
		const { startTime } = eventTimeRanges[event]
		const eventData = attendanceQrData?.data?.find(
			(record) => record?.title === event
		)
		const attStartTime = moment(startTime).valueOf()
		const attEndTime = moment(startTime)
			.add(eventData?.att_time_min ?? 120, 'minutes')
			.valueOf()
		const classEndTime = moment(startTime)
			.add(
				eventData?.att_time_min + eventData?.class_duration ?? 120,
				'minutes'
			)
			.valueOf()
		timingRow[event] = `${moment(attStartTime).format(
			'hh:mm A'
		)} - ${moment(attEndTime).format('hh:mm A')} - ${moment(
			classEndTime
		).format('hh:mm A')}`
	})

	const eventWisePresentCount = {}
	events.forEach((event) => {
		const eventRecords = recordsByEvent[event]
		const presentCount = eventRecords.filter((record) => {
			const attendanceTime = moment(record.createdAt).valueOf()
			const { startTime } = eventTimeRanges[event]
			const eventData = attendanceQrData?.data?.find(
				(rec) => rec?.title === event
			)
			return (
				attendanceTime >= startTime &&
				attendanceTime <
					moment(startTime).add(
						eventData?.class_duration ?? 120,
						'minutes'
					)
			)
		}).length

		eventWisePresentCount[event] = presentCount
	})

	// Step 4: Add event-wise present counts as a new row
	const presentCountRow = {
		'S.No': '',
		Name: 'Class-wise Present Count',
		'Connected Area': '',
		'Total Present': '',
		'Total Late': '',
		'Total Absent': '',
		Percentage: '',
	}

	events.forEach((event) => {
		presentCountRow[event] = `${eventWisePresentCount[event]}`
	})

	// Step 7: Export to Excel
	const generateExcel = (data, fileName) => {
		const workbook = new ExcelJS.Workbook()
		const worksheet = workbook.addWorksheet('Attendance')
		data.forEach((row) => {
			worksheet.addRow(Object.values(row))
		})

		// Adjust column widths
		worksheet.columns = [
			{ width: 5 },
			{ width: 25 },
			{ width: 25 },
			{ width: 13 },
			{ width: 13 },
			{ width: 13 },
			{ width: 20 },
			...Object.keys(formattedAttendanceMatrix?.[0]).map(() => ({
				width: 20,
			})), // Expanded width for event columns
		]
		;[1, 2, 3, 4, 5].forEach((rowIndex) => {
			const row = worksheet.getRow(rowIndex)
			row.eachCell((cell) => {
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: rowIndex == 5 ? 'D9EAF7' : 'FFFFCC',
					}, // Light yellow
				}
				cell.font = {
					bold: true,
				}
			})
		})

		workbook.xlsx
			.writeBuffer()
			.then((buffer) => FileSaver.saveAs(new Blob([buffer]), fileName))
			.catch((err) => console.log('Error writing excel export', err))
	}

	// Generate Excel file
	generateExcel(
		[
			speakerRow,
			dateRow,
			timingRow,
			presentCountRow,
			Object.keys(formattedAttendanceMatrix?.[0]),
			...formattedAttendanceMatrix,
		],
		`Attendance_Report.xlsx`
	)
}

export const generateAndDownloadReport = ({
	selectedRows,
	attendanceQrData,
	toggleLoader = () => {},
}) => {
	if (selectedRows.length === 0) {
		alert('Please select some rows to generate attendance report')
		return
	}
	const promises = selectedRows.map((eventId) => {
		return getUserAttendanceList({
			page: 1,
			per_page: 500,
			eventId: eventId,
		})
	})
	toggleLoader(true)
	Promise.all(promises).then((result) => {
		toggleLoader(false)
		const data = compileReport({ result, attendanceQrData })
		compileSummaryAndDownload(data)
	})
}
