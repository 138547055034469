import React, { Component } from 'react'
import './style.scss'
import {
	Button,
	Card,
	CardContent,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2';
import { getAreaDetails } from '../../services/area'
import { getSummary } from '../../services/dashboard'
import MainGrid from '../AppDashboard/components/MainGrid'
import styled from 'styled-components';

function generateDashboardData(data) {
	const {
		totalMemberCount,
		pendingMemberCount,
		nonCoastokMemberCount,
		coastokMemberCount,
		frontLinerCount,
		facilitatorCount,
		totalQuestionCount,
		answeredQuestionCount,
		unansweredQuestionCount,
		areaLeaderCount,
		assistantDirectorCount,
		areaCount,
		incompleteBookingCount,
		paymentCompleteBookingCount,
		paymentPendingBookingCount,
		subsidyRequestedBookingCount,
	} = data || {}
	const dashboardData = [
		{
			title: 'Total Members',
			value: `${totalMemberCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				200, 240, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320,
				360, 340, 380, 360, 400, 380, 420, 400, 640, 340, 460, 440, 480,
				460, 600, 880, 920,
			].slice(0, 30),
		},

		{
			title: 'COASTOK Members',
			value: `${coastokMemberCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				90, 110, 120, 130, 140, 150, 120, 150, 140, 150, 160, 170, 180,
				190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310,
				320, 330, 340, 350,
			].slice(0, 30),
		},
		{
			title: 'Pending Members (rounds atleast 4)',
			value: `${pendingMemberCount}`,
			interval: 'Last 30 days',
			trend: 'down', // Adjust based on actual trend logic
			data: [
				180, 200, 160, 180, 140, 160, 130, 160, 190, 170, 210, 200, 180,
				230, 210, 240, 230, 260, 240, 300, 220, 290, 260, 330, 290, 350,
				310, 360, 410, 450,
			].slice(0, 30),
		},
		{
			title: 'NON-COASTOK Members',
			value: `${nonCoastokMemberCount}`,
			interval: 'Last 30 days',
			trend: 'neutral', // Adjust based on actual trend logic
			data: [
				// Example trend data
				100, 120, 140, 160, 180, 200, 150, 180, 160, 170, 180, 210, 200,
				220, 240, 250, 270, 260, 280, 290, 300, 310, 320, 330, 340, 350,
				360, 380, 400, 420,
			].slice(0, 30),
		},
		{
			title: 'Frontliners',
			value: `${frontLinerCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				50, 60, 55, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120,
				125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185,
				190, 195,
			].slice(0, 30),
		},
		{
			title: 'Facilitators',
			value: `${facilitatorCount}`,
			interval: 'Last 30 days',
			trend: 'down', // Adjust based on actual trend logic
			data: [
				// Example trend data
				30, 40, 35, 45, 50, 40, 55, 50, 60, 55, 65, 60, 70, 75, 80, 85,
				90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150,
				155,
			].slice(0, 30),
		},

		{
			title: 'Area Leaders',
			value: `${areaLeaderCount}`,
			interval: 'Last 30 days',
			trend: 'neutral', // Adjust based on actual trend logic
			data: [
				// Example trend data
				60, 70, 65, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125,
				130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190,
				195, 200, 205,
			].slice(0, 30),
		},
		{
			title: 'Assistant Directors',
			value: `${assistantDirectorCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
				100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160,
				165,
			].slice(0, 30),
		},
		{
			title: 'Total Areas',
			value: `${areaCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				10, 12, 15, 18, 20, 22, 25, 28, 30, 32, 35, 37, 40, 42, 45, 48,
				50, 52, 55, 58, 60, 63, 65, 67, 70, 72, 75, 78, 80, 83,
			].slice(0, 30),
		},
		{
			title: 'Total Questions',
			value: `${totalQuestionCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				400, 420, 440, 460, 480, 500, 480, 520, 540, 560, 580, 600, 620,
				640, 660, 680, 700, 720, 740, 760, 780, 800, 820, 840, 860, 880,
				900, 920, 940, 960,
			].slice(0, 30),
		},
		{
			title: 'Answered Questions',
			value: `${answeredQuestionCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				300, 320, 340, 360, 380, 400, 360, 420, 440, 460, 480, 500, 520,
				540, 560, 580, 600, 620, 640, 660, 680, 700, 720, 740, 760, 780,
				800, 820, 840, 860,
			].slice(0, 30),
		},
		{
			title: 'Unanswered Questions',
			value: `${unansweredQuestionCount}`,
			interval: 'Last 30 days',
			trend: 'down', // Adjust based on actual trend logic
			data: [
				// Example trend data
				100, 120, 130, 140, 150, 160, 150, 160, 170, 180, 190, 200, 210,
				220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340,
				350, 360, 370, 380,
			].slice(0, 30),
		},
		{
			title: 'Incomplete Bookings',
			value: `${incompleteBookingCount}`,
			interval: 'Last 30 days',
			trend: 'down', // Adjust based on actual trend logic
			data: [
				// Example trend data
				80, 90, 85, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140,
				145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200, 205,
				210, 215, 220, 225,
			].slice(0, 30),
		},
		{
			title: 'Payment Complete Bookings',
			value: `${paymentCompleteBookingCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				70, 80, 85, 90, 95, 100, 105, 110,

				115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175,
				180, 185, 190, 195, 200, 205, 210, 215, 220, 225, 230, 235, 240,
				245,
			].slice(0, 30),
		},
		{
			title: 'Payment Pending Bookings',
			value: `${paymentPendingBookingCount}`,
			interval: 'Last 30 days',
			trend: 'down', // Adjust based on actual trend logic
			data: [
				// Example trend data
				90, 95, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
				210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330,
				340, 350, 360, 370,
			].slice(0, 30),
		},
		{
			title: 'Subsidy Requested Bookings',
			value: `${subsidyRequestedBookingCount}`,
			interval: 'Last 30 days',
			trend: 'up', // Adjust based on actual trend logic
			data: [
				// Example trend data
				50, 60, 55, 70, 65, 80, 85, 90, 95, 100, 105, 110, 115, 120,
				125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185,
				190, 195, 200,
			].slice(0, 30),
		},
	]

	// This `dashboardData` array now includes all the state variables with the required format.
	return dashboardData
}

class DashboardContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fromDate: undefined,
			toDate: undefined,
			areas: [{ _id: '', name: 'All' }],
			areaId: '',
		}
	}

	componentDidMount() {
		this.getSummaryDetails()
		getAreaDetails({ per_page: 100 }).then((res) => {
			this.setState({
				areas: [
					{ _id: '', name: 'All' },
					...res.data.data.map((area) => ({
						_id: area._id,
						name: area.name,
					})),
				],
			})
			localStorage.setItem('AREAS', JSON.stringify(res.data.data))
		})
	}

	getSummaryDetails = async () => {
		const { areaId, fromDate, toDate } = this.state
		const summary = await getSummary({ areaId, fromDate, toDate })
		this.setState({
			totalMemberCount: summary?.data?.totalMemberCount,
			pendingMemberCount: summary?.data?.pendingMemberCount,
			nonCoastokMemberCount:
				summary?.data?.nonCoastokMemberCount -
				summary?.data?.pendingMemberCount,
			coastokMemberCount: summary?.data?.coastokMemberCount,
			frontLinerCount: summary?.data?.frontLinerCount,
			facilitatorCount: summary?.data?.facilitatorCount,
			totalQuestionCount: summary?.data?.totalQuestionCount,
			answeredQuestionCount: summary?.data?.answeredQuestionCount,
			unansweredQuestionCount: summary?.data?.unansweredQuestionCount,
			areaLeaderCount: summary?.data?.areaLeaderCount,
			assistantDirectorCount: summary?.data?.assistantDirectorCount,
			areaCount: summary?.data?.areaCount,
			incompleteBookingCount: summary?.data?.incompleteBookingCount,
			paymentCompleteBookingCount:
				summary?.data?.paymentCompleteBookingCount,
			paymentPendingBookingCount:
				summary?.data?.paymentPendingBookingCount,
			subsidyRequestedBookingCount:
				summary?.data?.subsidyRequestedBookingCount,
			monthlySadhnaCounts: summary.data?.monthlySadhnaCounts,
			weeklySadhnaCounts: summary.data?.weeklySadhnaCounts,
			topUserMonthlyCounts: summary.data?.topUserMonthlyCounts,
		})
	}

	handleChange = (name, e) => {
		this.setState({ [name]: e.target.value })
	}

	render() {
		const {
			fromDate,
			toDate,
			areaId,
			areas,
			monthlySadhnaCounts = [],
			weeklySadhnaCounts = [],
			topUserMonthlyCounts = [],
		} = this.state
		const dashboardData = generateDashboardData(this.state)
		return (
			<div className="dashboard-container">
				<h2 className="greeting">Welcome back,</h2>
				<Grid container spacing={2}>
					<Grid size={{ xs: 4, md: 2 }}>
						<div className="select-area">
							<InputLabel shrink id="demo-simple-select-label">
								Select Area
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={areaId}
								onChange={(e) => this.handleChange('areaId', e)}
								displayEmpty
								className="w-full"
								size='small'
							>
								{areas.map((area) => (
									<MenuItem key={area._id} value={area._id}>
										{area.name}
									</MenuItem>
								))}
							</Select>
						</div>
					</Grid>
					<Grid size={{ xs: 4, md: 2 }} className="align-center mt-6">
						<Button
							variant="contained"
							onClick={this.getSummaryDetails}
							className="get-stats-btn"
							size='small'
						>
							Get Stats
						</Button>
					</Grid>
				</Grid>

				<MainGrid
					data={dashboardData}
					monthlySadhnaCounts={monthlySadhnaCounts}
					weeklySadhnaCounts={weeklySadhnaCounts}
					topUserMonthlyCounts={topUserMonthlyCounts}
				/>
			</div>
		)
	}
}

const SummaryCard = ({ text, value }) => {
	return (
		<Card className="summary-card">
			<CardContent>
				<Typography>{text}</Typography>
				<Typography variant="h3">{value}</Typography>
			</CardContent>
		</Card>
	)
}

export default DashboardContainer
