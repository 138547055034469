import './style.scss'
import {
	Button,
	FormControl,
	Grid2,
	InputLabel,
	MenuItem,
	Paper,
	Select,
} from '@mui/material'
import { getUsers } from '../../services/user.js'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format, formatDate } from 'date-fns'
import { getSadhna } from '../../services/sadhna.js'
import { GridSearchIcon } from '@mui/x-data-grid'
import { getLast15thDate, SadhnaHelper } from './helper.js'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../constants/actions.js'
import store from '../../store.js'
import { SADHNA_GROUP, USER_ROLE } from '../../constants/index.js'
import CustomizedDataGrid from '../AppDashboard/components/CustomizedDataGrid.js'
import { useEffect, useMemo, useState } from 'react'
import { useQueries, useQuery } from '@tanstack/react-query'
import { DownloadForOffline } from '@mui/icons-material'
import moment from 'moment'
var FileSaver = require('file-saver')
const ExcelJS = require('exceljs')

export function valueFormatter(value) {
	return `${value}%`
}

const generateColumns = (data) => {
	const columns = [
		{ field: 'srNo', headerName: 'Sr No', width: 100 },
		{ field: 'userName', headerName: 'User Name', width: 200 },
		{
			field: 'connectedAreaName',
			headerName: 'Connected Area',
			width: 200,
		},
		{ field: 'dayCount', headerName: 'Day Count', width: 200 },
	]

	const rows = data.map((item, index) => {
		// Calculate averages for compiledSadhna
		const totalSadhna = item.compiledSadhna.length
		const averageSadhna = item.compiledSadhna.reduce((acc, day) => {
			Object.keys(day).forEach((key) => {
				acc[key] = (acc[key] || 0) + parseInt(day[key] || 0)
			})
			return acc
		}, {})

		Object.keys(averageSadhna).forEach((key) => {
			averageSadhna[key] = (averageSadhna[key] / totalSadhna).toFixed(2)
		})

		const totalTypeOfSadhna = Object.keys(averageSadhna).length

		averageSadhna.overallAverage = (
			Object.values(averageSadhna).reduce(
				(acc, val) => acc + parseInt(val || 0),
				0
			) / totalTypeOfSadhna
		)?.toFixed?.(2)

		averageSadhna.overallPercentage = `${(
			(averageSadhna.overallAverage * 100) /
			25
		)?.toFixed?.(2)} %`

		// Create row object
		return {
			id: item._id || index,
			srNo: index + 1,
			userName: item.name || 'N/A',
			dayCount: totalSadhna,
			connectedAreaName: item?.connectedAreaId?.name,
			...averageSadhna,
		}
	})

	// Add dynamic columns for each sadhna
	const sadhnaWithScore = data.find((el) => el.compiledSadhna.length > 0)
	if (sadhnaWithScore && sadhnaWithScore?.compiledSadhna?.length > 0) {
		const sampleSadhna = sadhnaWithScore.compiledSadhna[0]
		Object.keys(sampleSadhna).forEach((key) => {
			columns.push({
				field: key,
				headerName: key
					.replace(/([A-Z])/g, ' $1')
					.replace(/^./, (str) => str.toUpperCase()), // Format header
				width: 150,
			})
		})
	}
	columns.push(
		{
			field: 'overallAverage',
			headerName: 'Overall Average',
			width: 150,
		},

		{
			field: 'overallPercentage',
			headerName: 'Overall Percentage',
			width: 150,
		}
	)

	return { columns, rows }
}

const downloadReport = ({ rows, columns, fileName = 'SadhnaReport.xlsx' }) => {
	const workbook = new ExcelJS.Workbook()
	const worksheet = workbook.addWorksheet('Sadhna Report')

	// Define worksheet columns
	worksheet.columns = columns.map((col) => ({
		header: col.headerName, // Set the header name
		key: col.field, // Map the field name to the key
		width: col.width / 10, // Optional: Set width (adjust scale if needed)
	}))

	// Add data rows
	rows.forEach((row) => {
		const rowData = columns.map((col) => row[col.field])
		worksheet.addRow(rowData)
	})

	// Generate Excel file and trigger download
	workbook.xlsx.writeBuffer().then((buffer) => {
		FileSaver.saveAs(new Blob([buffer]), fileName)
	})
}

const SadhnaReportContainer = () => {
	const [filter, setFilter] = useState({
		category: 4,
		user: null,
		from: getLast15thDate(),
		to: new Date(),
		areaId: '',
	})

	const [usersWithSadhna, setUsersWithSadhna] = useState([])

	// Fetch users based on the filter
	const { data: users = [], isLoading: usersLoading } = useQuery({
		queryKey: ['users', filter.category],
		queryFn: () =>
			getUsers({
				...(typeof filter.category == 'number'
					? { userRole: filter.category }
					: { sadhnaGroup: SADHNA_GROUP[filter.category].id }),
				per_page: 1000,
				select: '_id name displayName',
				isShort: true,
			}).then((res) => res?.data?.data || []),
		enabled: !!filter.category, // Ensure the query runs only when the category is available
	})

	// Fetch sadhna data for each user
	const sadhnaQueries = useQueries({
		queries: users.map((user) => ({
			queryKey: [
				'sadhna',
				user._id,
				format(new Date(filter.from), 'yyyy-MM-dd'),
				format(new Date(filter.to), 'yyyy-MM-dd'),
			],
			queryFn: () =>
				getSadhna({
					from: format(new Date(filter.from), 'yyyy-MM-dd'),
					to: format(new Date(filter.to), 'yyyy-MM-dd'),
					userId: user._id,
					perPage: 365,
				}).then((res) => res?.data?.data || []),
			enabled: !!filter.from && !!filter.to, // Ensure the query runs only when the date range is available
		})),
	})

	const isSadhnaLoading = sadhnaQueries.some((query) => query.isLoading)

	useEffect(() => {
		if (usersLoading || isSadhnaLoading) {
			store.dispatch({ type: SHOW_MAIN_LOADER })
		} else {
			store.dispatch({ type: HIDE_MAIN_LOADER })
			const updatedUsers = users.map((user, index) => ({
				...user,
				id: user._id,
				sadhnaData: sadhnaQueries[index]?.data || [],
				compiledSadhna: sadhnaQueries[index]?.data?.map((sadhna) =>
					SadhnaHelper.getIndividualActivityMarks(sadhna)
				),
			}))
			setUsersWithSadhna(updatedUsers)
		}
	}, [usersLoading, isSadhnaLoading, users])

	const onChangeFilter = (key, value) => {
		setFilter((prevData) => ({
			...prevData,
			[key]: value,
		}))
	}

	const { rows, columns } = useMemo(
		() => generateColumns(usersWithSadhna),
		[usersWithSadhna]
	)

	const onRowClick = () => {}

	return (
		<Paper>
			<Grid2
				container
				direction="row"
				spacing={2}
				sx={{ padding: '15px' }}
			>
				<Grid2 item size={2}>
					<FormControl
						variant="outlined"
						size="small"
						className="d-flex width-50"
						style={{ width: '100%' }}
					>
						<InputLabel>Category</InputLabel>
						<Select
							value={filter.category}
							onChange={(e) =>
								onChangeFilter('category', e.target.value)
							}
							label="Class Category"
							name="classCategory"
						>
							{[
								...Object.entries(USER_ROLE),
								...Object.entries(SADHNA_GROUP)
									.filter(
										(el) =>
											![
												'OTHER',
												'getById',
												'getAll',
											].includes(el[0])
									)
									.map((el) => [el[0], el[0]]),
							].map(([key, value]) => (
								<MenuItem value={key}>{value}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid2>
				{/* <Grid2 item size={2}>
					<Autocomplete
						options={ALL_AREAS || []}
						getOptionLabel={(option) => option.name || ''}
						filterSelectedOptions
						isOptionEqualToValue={(option, value) =>
							option?._id === value._id
						}
						onChange={(e, newValue) => {
							setFilter((prev) => ({
								...prev,
								areaId: newValue?._id,
								user: null,
							}))
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Connected Area"
								placeholder="Start typing Area Name"
							/>
						)}
					/>
				</Grid2> */}
				{/* <Grid2 item size={2}>
					<Autocomplete
						options={users || []}
						getOptionLabel={(option) => {
							return (
								(option.initiatedName || option.name
									? `${option.initiatedName || option.name}` +
									  (option.phone ? ` (${option.phone})` : '')
									: option.initiatedName || option.name) +
								option?._id
							)
						}}
						filterSelectedOptions
						isOptionEqualToValue={(option, value) =>
							option?._id === value?._id
						}
						value={filter.user}
						onChange={(e, newValue) => {
							onChangeFilter('user', newValue)
						}}
						onBlur={() => {}}
						disabled={!filter.areaId}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Facilitator"
								placeholder="Devotee Name"
							/>
						)}
					/>
				</Grid2> */}
				<Grid2 item size={2}>
					<DatePicker
						label="Start Date"
						format="dd/MM/yyyy"
						value={filter.from}
						onChange={(e) => {
							onChangeFilter('from', e)
							// onChangeFilter('to', getSeventhDate(e))
						}}
						inputVariant="outlined"
					/>
				</Grid2>
				<Grid2 item size={2}>
					<DatePicker
						label="End Date"
						format="dd/MM/yyyy"
						value={filter.to}
						onChange={(e) => {
							onChangeFilter('to', e)
						}}
						inputVariant="outlined"
						minDate={filter.from} // Min is 6 days after 'from'
						// maxDate={getSeventhDate(filter.from)}
					/>
				</Grid2>
				<Grid2 item size={2}>
					<Button
						variant="contained"
						color="primary"
						style={{ marginRight: '10px' }}
					>
						<GridSearchIcon />
						Search
					</Button>
				</Grid2>
				<Grid2 item size={2}>
					<Button
						variant="contained"
						color="primary"
						style={{ marginRight: '10px' }}
						onClick={() =>
							downloadReport({
								rows,
								columns,
								fileName: `Sadhna Report ${
									USER_ROLE[filter.category]
								} ${moment(new Date(filter.from)).format(
									'Do MMMM yy'
								)}-${moment(new Date(filter.to)).format(
									'Do MMMM yy'
								)}.xlsx`,
							})
						}
						startIcon={<DownloadForOffline />}
					>
						Download Report
					</Button>
				</Grid2>
			</Grid2>
			<Grid2
				container
				direction="row"
				justifyContent="center"
				spacing={2}
				maxWidth={'90vw'}
			>
				<Grid2
					size={{ md: 12, lg: 12 }}
					style={{ background: 'white' }}
				>
					<CustomizedDataGrid
						rows={rows}
						columns={columns}
						onRowClick={onRowClick}
					/>
				</Grid2>
			</Grid2>
		</Paper>
	)
}

export default SadhnaReportContainer
