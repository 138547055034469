import * as React from 'react'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Copyright from '../internals/components/Copyright'
import CustomizedDataGrid from './CustomizedDataGrid'
import WeekelySadhnaBarChart from './WeekelySadhnaBarChart'
import StatCard from './StatCard'
import ChartUserByStatus from './ChartUserByCountry'
import MonthlySadhnaChart from './MonthlySadhnaChart'
import { useNavigate } from 'react-router-dom'

function transformUsers(userData) {
	return userData.map((el, index) => ({
		...el,
		id: el.userId,
		rank: index + 1,
	}))
}

const columns = [
	{
		field: 'serialNumber',
		headerName: 'Rank',
		headerAlign: 'right',
		align: 'right',
		flex: 0.2,
		minWidth: 60,
		renderCell: (params, index) => {
			// Calculate the serial number based on the row index
			return params.row.rank // Adding 1 to make it 1-based index
		},
	},
	{
		field: 'imageUrl',
		headerName: 'Profile Image',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 120,
		renderCell: (params) => (
			<img
				src={params.value}
				alt="Profile"
				style={{
					width: '30px',
					height: '30px',
					borderRadius: '50%',
					justifyContent: 'center',
					alignItems: 'center',
					alignSelf: 'center',
				}}
			/>
		),
	},
	{
		field: 'displayName',
		headerName: 'Name',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 150,
	},
	{
		field: 'email',
		headerName: 'Email',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 200,
	},
	{
		field: 'area',
		headerName: 'Area',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 200,
	},
	{
		field: 'monthlyCount',
		headerName: 'Monthly Count',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 130,
	},
	{
		field: 'averageChantRounds',
		headerName: 'Avg Chant Rounds',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 150,
	},
	{
		field: 'averageHearDuration',
		headerName: 'Avg Hear Duration (Min)',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 150,
	},
	{
		field: 'averageReadDuration',
		headerName: 'Avg Read Duration (Min)',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 150,
	},
	{
		field: 'averageServiceDuration',
		headerName: 'Avg Service Duration (Min)',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		minWidth: 150,
	},
]

export default function MainGrid({
	data = [],
	monthlySadhnaCounts,
	weeklySadhnaCounts,
	topUserMonthlyCounts,
}) {
	const navigate = useNavigate()
	const onRowClick = (rowData) => {
		const userId = rowData?.row?.userId
		navigate(`/sadhna?userId=${userId}`)
	}
	return (
		<Box
			sx={{
				width: '100%',
				maxWidth: { sm: '100%', md: '1700px' },
			}}
		>
			{/* cards */}
			<Typography component="h2" variant="h6" sx={{ mb: 2 }}>
				Overview
			</Typography>
			<Grid
				container
				spacing={2}
				columns={12}
				sx={{ mb: (theme) => theme.spacing(2) }}
			>
				{data.map((card, index) => (
					<Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
						<StatCard {...card} />
					</Grid>
				))}
				{/* <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
					<HighlightedCard />
				</Grid> */}
				<Grid size={{ sm: 12, md: 6 }}>
					<MonthlySadhnaChart
						monthlySadhnaCounts={monthlySadhnaCounts}
					/>
				</Grid>
				<Grid size={{ sm: 12, md: 6 }}>
					<WeekelySadhnaBarChart
						weeklySadhnaCounts={weeklySadhnaCounts}
					/>
				</Grid>
			</Grid>
			<Typography component="h2" variant="h6" sx={{ mb: 2 }}>
				Last 1 Month Top 50 Users
			</Typography>
			<Grid container spacing={2} columns={12}>
				<Grid size={{ md: 12, lg: 9 }} style={{ background: 'white' }}>
					<CustomizedDataGrid
						rows={transformUsers(topUserMonthlyCounts)}
						columns={columns}
						onRowClick={onRowClick}
					/>
				</Grid>
				<Grid size={{ xs: 12, lg: 3 }}>
					<Stack
						gap={2}
						direction={{ xs: 'column', sm: 'row', lg: 'column' }}
					>
						{/* <CustomizedTreeView /> */}
						<ChartUserByStatus data={data} />
					</Stack>
				</Grid>
			</Grid>
			<Copyright sx={{ my: 4 }} />
		</Box>
	)
}
